// src/components/VideoList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './VideoList.css';
import ReactPlayer from 'react-player';
import VideoYT from './VideoYT';

function VideoList() {
  const [videos, setVideos] = useState([
    {
      title: 'React js Tuto To Do List Debutant | Reconversion professionnelle',
      url: 'https://www.youtube.com/watch?v=DsJ956fW0Iw&t=8s',
    },
    {
      title: 'Quelques fonctions Javascript pour gérer les tableaux - Débutants | Reconversion professionnelle',
      url: 'https://www.youtube.com/watch?v=rjkI-yqCnVs',
    }
  ]);

  useEffect(() => {
    /*const fetchVideos = async () => {
      const response = await axios.get(
        'https://www.googleapis.com/youtube/v3/search', {
          params: {
            part: 'snippet',
            channelId: 'YOUR_CHANNEL_ID', // Remplacez par votre ID de chaîne YouTube
            maxResults: 5,
            key: 'YOUR_API_KEY', // Remplacez par votre clé API YouTube
          }
        }
      );
      setVideos(response.data.items);
    };

    fetchVideos();
    */
  }, []);

  return (
    <div className="video-list">
      <h3>Dernières vidéos YouTube</h3>
      <div className="videos">
        {/*videos.map(video => (
          <div key={video.id.videoId} className="video">
            <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
            <div className="video-info">
              <h4>{video.snippet.title}</h4>
              <p>{video.snippet.description}</p>
            </div>
          </div>
        ))*/}
        {
          videos.map(video => {
            return (
              <VideoYT video={video} />
            )
          })
        }
      </div>
    </div>
  );
}

export default VideoList;