import React, {useState} from 'react';


function Footer() {
    return (
        <div>
            © 2024 LeDevFacile.
        </div>
    )
}


export default Footer