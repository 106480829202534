import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import './VideoYT.css';


function VideoYT({video}) {

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 10}}>
            <ReactPlayer url={video.url} width={window.innerWidth < 768 ? 250 : 500} height={window.innerWidth < 768 ? 200 : 300} />
            <div style={{textAlign: 'center'}}>{video.title}</div>
        </div>
    )
}

export default VideoYT;